import React from 'react';
import HTMLFlipBook from "react-pageflip";

// book characters
import Solomon from '../assets/book/solomon.png';
import Rugbert from '../assets/book/rugbert.png';
import Santa from '../assets/book/santa-claus.png';
import MagicEddy from '../assets/book/magic-eddy.png';
import Ethan from '../assets/book/ethan.png';
import Adam from '../assets/book/adam.png';
import Polyna from '../assets/book/polyna.png';
import BookCover from '../assets/book/bookcover.png';

type Props = {
    bookRef: React.LegacyRef<HTMLDivElement>|null;
    setShowBook: (showBook: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

function Book({bookRef, setShowBook, setShowBgOverlay}: Props) {
  return (
    <div className="book-wrapper" ref={bookRef}>
        <div>

        </div>
        <div className="book-wrapper__full-book">
            <img className="book-cover" src={BookCover} alt="" />
            {/*
            // @ts-ignore */}
            <HTMLFlipBook className="book" width={450} height={670} drawShadow={false}>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Solomon</h2>
                        <img className="character" src={Solomon} alt="" height="400px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">… also called soly, is 23 years old & lives in soleth village.<br /><br />
                            He loves to spend time with his best friends through the web3 space.<br /><br />
                            As his name already says, he is a man of peace.<br /><br />
                            His biggest wish is that everybody gets a great gift from Santa for Solmas.
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Rugbert</h2>
                        <img className="character" src={Rugbert} alt="" height="450px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            … is 31 years old and got rugged and scammed many times in his life.<br /><br />
                            The worst experience was that his wallet got drained and he lost all of his money.<br /><br />
                            Thats why he cant see other people being happy and wants to destroy Solmas.<br /><br />
                            Will he make it this time or can someone stop him?
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Santa Claus</h2>
                        <img className="character" src={Santa} alt="" height="400px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            Santa is 107 years old and does what Santa Claus does, giving people their gifts.<br /><br />
                            Regardless of what happens, he always has a plan b otherwise, he wouldnt be Santa, right?
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Magic Eddy</h2>
                        <img className="character" src={MagicEddy} alt="" height="300px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            … is 1 year old and is Santas newest helper.<br /><br />
                            He has unique and magical powers that work wonders.<br /><br />
                            Will he be able to stop Rugbert or is he too busy with the gifts?
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Ethan</h2>
                        <img className="character" src={Ethan} alt="" height="400px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            Ethan is 42 and Solomons best friend, although they don't have much in common.<br /><br />
                            He loves expensive shopping but hates high energy costs.<br /><br />
                            Ever since Polyna moved to Soleth Village, he only has eyes for her.<br /><br />
                            However, Polyna seems less interested.
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Adam</h2>
                        <img className="character" src={Adam} alt="" height="300px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            Adam is 34 years old and the shy guy in the group.<br /><br />
                            He enjoys spending time with his friends and secretly has a crush on Polyna.<br /><br />
                            Will he ever get his chance?
                        </p>
                    </div>
                </div>
                <div className="book__page left">
                    <div className="book__page__content">
                        <h2 className="title">Polyna</h2>
                        <img className="character" src={Polyna} alt="" height="350px" />
                    </div>
                </div>
                <div className="book__page right">
                    <div className="book__page__content">
                        <p className="bio">
                            Polyna is 27 years old and recently moved to Soleth Village.<br /><br />
                            There are already a few who have their eyes on her, but she has other plans. <br /><br />
                            She is happy to have met Solomon and spends a lot of time with him in her free time.
                        </p>
                    </div>
                </div>
            </HTMLFlipBook>
        </div>
    </div>
  );
}

export default React.memo(Book);
