
import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

const Background = React.memo(({bgRef, setXPostion, setIsLoading, setShowIntro, introAlreadyDone, setIsPlaying}: {bgRef: any, setXPostion: any, setIsLoading: any, setShowIntro: any, introAlreadyDone: any, setIsPlaying: any, width: any, height: any}, ) => {
    const originalBgWidth = 5760;
    const originalBgHeight = 1080;
    const currentHeight = window.innerHeight;
    const bgWidth = currentHeight / originalBgHeight * originalBgWidth;

    return (
        <Vimeo
            video="https://vimeo.com/772825827/e147d6cae2"
            autoplay
            muted
            loop
            background
            className="living-room__body__bg"
            width={bgWidth}
            height={currentHeight}
            ref={bgRef}
            onReady={() => {
                if (bgRef.current && bgRef.current.player && bgRef.current.player.element) {
                    const bgElement = bgRef.current.player.element;
                    const centerPostion = -((bgElement.offsetWidth / 2) - (window.innerWidth / 2));
                    setXPostion(centerPostion);
                }

                setTimeout(() => {
                    setIsLoading(false);
                    setIsPlaying(true);
                }, 1500);

                setTimeout(() => {
                    if (!introAlreadyDone) {
                        setShowIntro(true);
                    }
                }, 2000);
            }}
        />
    )
})

export default Background;