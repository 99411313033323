import React, {useState} from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask';
import Lock from "../assets/Lock.json";
import WalletConnect from './WalletConnect';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import PulseLoader from "react-spinners/PulseLoader";
import DoorUnlock from '../assets/door-unlock.wav';

type Props = {
    phoneRef: React.LegacyRef<HTMLDivElement>|null,
    isLocked: boolean,
    setShowPhone: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
    setIsLocked: (isLocked: boolean) => void,
    setShowAnimation: (startAnimation: boolean) => void,
};

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

function Phone({phoneRef, isLocked, setShowPhone, setShowBgOverlay, setIsLocked, setShowAnimation}: Props) {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [keyInput, setKeyInput] = useState('');
    const [keyError, setKeyError] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [audio] = useState(new Audio(DoorUnlock));

    const lottieOptions = {
        loop: false,
        autoplay: false,
        animationData: Lock,
    };

    const onUnlock = async () => {
        const token = await user?.getIdToken(true);
        setIsLoading(true);

        if (token) {
            const res = await fetch('https://us-central1-solmas-933e5.cloudfunctions.net/validateKey?' + new URLSearchParams({
                key: keyInput,
                token,
            }), { mode: 'cors' });

            const status = await res.json();
            await delay(1500);

            setIsLoading(false);

            if (status && status.success) {
                setIsLocked(false);
                audio.play();

                setTimeout(() => {
                    setShowPhone(false);
                }, 1200)

                setTimeout(() => {
                    setShowBgOverlay(false);
                }, 1400);

                setTimeout(() => {
                    setShowAnimation(true);
                }, 1800)

                setTimeout(() => {
                    navigate("/living-room");
                }, 3600)
            } else {
                setKeyError(status);
            }
        }
    }

    return (
        <div className="iphone-x" ref={phoneRef}>
            <i>Speaker</i>
            <b>Camera</b>
            <div className="iphone-x__content">
                <span className="iphone-x__title">Door Lock 2000</span>
                <Lottie options={lottieOptions} width={220} isPaused={isLocked} isStopped={isLocked} isClickToPauseDisabled={true} />
                {!user && <WalletConnect />}
            </div>
        </div>
    );
}

export default Phone;
