import React, {useRef, useState, useEffect} from 'react';
import Lottie from "react-lottie";
import LoadingAnimation from "../assets/Loading.json";

function Loading() {
    const [isMounted, setIsMounted] = useState(false);
    const wrapperRef: React.LegacyRef<HTMLDivElement>|null = useRef(null);

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingAnimation,
    };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <div className="loading-screen">
            <div className="loading-screen__wrapper" ref={wrapperRef}>
                <Lottie options={lottieOptions} width={370} />
            </div>
        </div>
    );
}

export default Loading;
