import React, { Component } from "react";
import { Steps } from "intro.js-react";

type Props = {showIntro: boolean, currentIntroStep: number};

export default class Intro extends Component<Props> {
    steps: any;

  constructor(props: any) {
    super(props);

    this.state = {
      stepsEnabled: true,
      initialStep: 0,
      steps: [
        {
            element: ".navigate.right",
            intro: "Click on the right panel to explore the room!",
            position: 'left',
            tooltipClass: 'living-room-tooltip right',
        },
        {
            element: ".navigate.left",
            intro: "Click on the left panel to navigate back!",
            position: 'right',
            tooltipClass: 'living-room-tooltip left',
        },
        {
          element: "#circularMenu",
          intro: "Lets checkout the action menu!",
          position: 'top',
          tooltipClass: 'living-room-tooltip top menu',
        },
        {
          element: "#circularMenu .keys",
          intro: "Tag 3 friends and increase your chances!",
          position: 'top',
          tooltipClass: 'living-room-tooltip top keys',
        },
        {
            element: ".list-layer",
            intro: "Click on the glowy elements to learn more about Solmas. Try it out!",
            position: 'left',
            tooltipClass: 'living-room-tooltip right intro',
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.currentIntroStep !== nextProps.currentIntroStep) {
        this.steps.introJs.goToStep(nextProps.currentIntroStep);
    }
  }

  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
    } = this.state as any;

    return (
      <div>
        <Steps
            enabled={this.props.showIntro}
            steps={steps}
            initialStep={initialStep}
            onExit={() => {
                //setShowIntro(false);
            }}
            options={{ showButtons: false, exitOnOverlayClick: false }}
            ref={(steps) => (this.steps = steps)}
        />
      </div>
    );
  }

}