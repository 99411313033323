import React, {useState} from 'react';
import { FaTwitter } from "react-icons/fa";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

type Props = {
    teamRef: React.LegacyRef<HTMLDivElement>|null;
    setShowTeam: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

const teamMembers = [
    {
        name: 'MrGoodDay',
        image: 'https://metadata.y00ts.com/y/7016.png',
        twitter: 'MrGoodDay91',
        bio: (
            <>
                MrGoodDay is the creative brain of Solmas and xcollective. He always thinks out of the box and has a good feeling for aesthetics and design.
                <br /><br />Sometimes he can be a perfectionist when it comes to the design details, but that’s his credo - always delivering high quality. Before Solmas MrGoodDay worked for 7 years as an IT consultant in various fields.
            </>
        ),
    },
    {
        name: 'Nifty Dev',
        image: 'https://bafybeictnqiohaaewoh6b4cr5e4phgajfczdjs6bi6ood6iffiu63qpu6i.ipfs.nftstorage.link/1199.png?ext=png',
        twitter: 'nifty_dev',
        bio: (
            <>
                Nifty Dev is our lead developer. He is not just any developer, as he has already proven his talent in several start-ups in Silicon Valley, Tel Aviv and Switzerland.
                <br /><br />His open source code has several billion downloads. Now it's time to put his own vision into the world and make it a better place.
            </>
        ),
    },
    {
        name: 'rf_ape',
        image: 'https://cdn.valhallalabs.xyz/ape/8044.png?ext=png',
        twitter: 'rf_ape_',
        bio: (
            <>
                Robin is our marketing geek. He gained his experience while working in digital marketing for big fashion brands for many years.
                <br /><br />His strategic way of thinking through every step in combination with his entertaining streak completes his personality - he was always the class clown and sometimes he still is.
            </>
        ),
    },
    {
        name: 'Nifty Zephon',
        image: 'https://cdn.valhallalabs.xyz/ape/6197.png?ext=png',
        twitter: 'NiftyZephon',
        bio: (
            <>
                Nifty Zephon is the structural mind of Solmas. His ability to organize and plan everything is essential for the fast and qualitative progress of the project.
                <br /><br />A small example from his private to-do list: 3476 completed tasks in 2022 - he plans every shit. Nifty Zephon  gained his experience several years as an IT consultant at one of the biggest insurance companies in Switzerland.
            </>
        ),
    },
]

function Team({teamRef, setShowTeam, setShowBgOverlay}: Props) {
    const [currentTeamMember, setCurrentTeamMember] = useState(0);

    const onPrev = () => {
        if (currentTeamMember === 0) {
            setCurrentTeamMember(3);
        } else {
            setCurrentTeamMember(currentTeamMember - 1);
        }
    }

    const onNext = () => {
        if (currentTeamMember === 3) {
            setCurrentTeamMember(0);
        } else {
            setCurrentTeamMember(currentTeamMember + 1);
        }
    }

  return (
    <div className="team" ref={teamRef}>
        <div className="team__content">
            <div className="carousel">
                <div className="arrows">
                    <div className="arrow left" onClick={onPrev}><BsArrowLeft size={30} /></div>
                    <div className="arrow right" onClick={onNext}><BsArrowRight size={30} /></div>
                </div>
                {teamMembers.map((member, i) => (
                    <div className={`team__member ${i === currentTeamMember ? 'active' : ''}`}>
                        <div className="frame">
                            <div className="border">
                                <div className="border__left">
                                    <div className="image">
                                        <img src={member.image} alt="" />
                                    </div>
                                </div>
                                <div className="border__right">
                                    <div className="bio">
                                        <div className="bio__name">
                                            <span className="bio__name__text">{member.name} | </span>
                                            <a className="bio__name__twitter" href={`https://twitter.com/${member.twitter}`} target="_blank">
                                                <FaTwitter size={45} />
                                            </a>
                                        </div>
                                        <div className="bio__text">
                                            {member.bio}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
}

export default Team;
