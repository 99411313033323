import React from 'react';

type Props = {
    aboutRef: React.LegacyRef<HTMLDivElement>|null;
    setShowAbout: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

function About({aboutRef, setShowAbout, setShowBgOverlay}: Props) {
  return (
    <div className="about" ref={aboutRef}>
        <div className="letter">
            <p>Dear Friends,</p>
            <p>Solmas is a FREE mint with a guaranteed prize in each gift box!</p>
            <p>The community on Santa's List will mint a free gift box and open it on the 23rd December through a unique reveal experience. The surprise contains a guaranteed prize with a chance to win various categories such as NFTs, SPL tokens, exclusive services and IRL products (check out our gifts under the tree on the right side to see the overview).</p>
            <p>The prize pool is filled by our sponsoring partners & and us - we are xcollective.</p>
            <p>Sounds like a dream - so why non-profit?<br />
Our goal as xcollective is to build our reputation & trust within the space as we’re launching our already finished SaaS through Solmas.
By participating this unique experience you will be the first user of our SaaS which will be made public after. The main goal of Solmas is to onboard users to our SaaS, for that reason we don't take any profits and ensure everyone will receive some value.</p>
            <p>#MerrySolmas everyone</p>
        </div>
    </div>
  );
}

export default About;
