import React from 'react'
import { useWallet } from '@solana/wallet-adapter-react';
import {
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import PulseLoader from "react-spinners/PulseLoader";
import { sign } from 'tweetnacl';
import { useEffect } from 'react';
import bs58 from "bs58";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithCustomToken, signOut } from 'firebase/auth';
import { auth } from '../services/firebase';

export default function WalletConnect() {
  const { publicKey, signMessage, disconnect } = useWallet();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (publicKey) {
      initSignature();
    }
  }, [publicKey]);

  const initSignature = async () => {
    if (!user && !loading) {
      const messageToSign = await getMessageToSign();
      await handleSignMessage(messageToSign);
    }
  }

  const getMessageToSign = async () => {
    const res = await fetch('https://us-central1-solmas-933e5.cloudfunctions.net/getMessageToSign?' + new URLSearchParams({
      address: publicKey!.toBase58(),
    }), { mode: 'cors' });

    const messageToSign = await res.json();

    return messageToSign.messageToSign;
  }

  const handleSignMessage = async (messageToSign: string) => {
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error('Wallet not connected!');
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage) throw new Error('Wallet does not support message signing!');

      const message = new TextEncoder().encode(messageToSign);

      // Sign the bytes using the wallet
      const signature = await signMessage(message);

      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey.toBytes())) throw new Error('Invalid signature!');

      const res = await fetch('https://us-central1-solmas-933e5.cloudfunctions.net/getJwt?' + new URLSearchParams({
          address: publicKey.toBase58(),
          signature: bs58.encode(signature),
      }), { mode: 'cors' });

      const tokenData = await res.json();

      await signInWithCustomToken(auth, tokenData.token);
    } catch (error: any) {}
  }

  return (
    <>
      {publicKey && (
        <>
            {(!user && !loading) &&
              <button className="wallet-adapter-button" onClick={initSignature}>
                <PulseLoader color="#fff" size={5} />
                Sign Message
              </button>}
            {user && (
                <>
                    {publicKey.toBase58().slice(0, 6)}
                    {'...'}
                    {publicKey.toBase58().slice(publicKey.toBase58().length - 6)}
                    <div
                        className="flex cursor-pointer items-center gap-3 rounded-lg py-2.5 px-3 text-sm font-medium text-gray-900 transition hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800"
                        onClick={async () => {
                        await signOut(auth);
                        await disconnect();
                        }}
                    >
                        <span className="grow uppercase">Disconnect</span>
                    </div>
                </>
            )}
        </>
      )}
      {!publicKey && (
        <WalletMultiButton className="relative inline-flex shrink-0 items-center justify-center overflow-hidden text-center text-xs font-medium tracking-wider outline-none transition-all sm:text-sm bg-brand border-brand hover:-translate-y-0.5 hover:shadow-large focus:-translate-y-0.5 focus:shadow-large focus:outline-none text-white rounded-full px-5 sm:px-8 h-10 sm:h-12 shadow-main hover:shadow-large">
          Connect Wallet
        </WalletMultiButton>
      )}
    </>
  );
}
