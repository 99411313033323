import React, { useEffect, useRef, useState } from 'react';
import { Steps } from 'intro.js-react';
import { CSSTransition } from 'react-transition-group';
import Vimeo from '@u-wave/react-vimeo';
import {useWindowSize} from '../../hooks/useWindow';
import Loading from '../Loading';
import Phone from '../Phone';
import { useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { get, ref } from "firebase/database";
import { auth, db } from '../../services/firebase';

// layers
import Door from '../../assets/layers/door.png';

const steps = [
        {
            element: ".interactive-layer",
            intro: "Welcome to my Crib! I was already expecting you...",
            position: 'right',
        },
        {
            element: ".interactive-layer",
            intro: "Shall we go inside? I hope you still have they key I gave you!",
            position: 'right',
        },
  ];

function Home() {
    const [width] = useWindowSize();
    const [showBgOverlay, setShowBgOverlay] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showIntro, setShowIntro] = useState(false);
    const [isLocked, setIsLocked] = useState(true);
    const [showAnimation, setShowAnimation] = useState(false);
    const bgRef: React.LegacyRef<HTMLVideoElement>|null = useRef(null);
    const bgOverlayRef = useRef(null);
    const loadingRef = useRef(null);
    const phoneRef = useRef(null);
    const unlockAnimationRef = useRef(null);
    let navigate = useNavigate();
    const introAlreadyDone = localStorage.getItem('home-intro-done');
    const [user, loading, error] = useAuthState(auth);

    const getUserData = async (uid: string) => {
        const userRef = ref(db, `users/${uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            const userData = snapshot.val();

            if (userData) {
                navigate('/living-room')
            }
        }
    }

    const overlays = [
        {
            image: Door,
            top: 420,
            left: 1605,
            height: 1277,
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowPhone(true);
                }, 200);
            },
        },
    ];

    useEffect(() => {
        if (user) {
            getUserData(user.uid);
        }
    }, [user]);

    const renderOverlays = (overlay: any) => {
        const layerHeight = window.innerHeight / 1080;
        const originalRatio = 16 / 9;
        const currentRatio = window.innerWidth / window.innerHeight;
        let currentDimension = 100 / originalRatio * currentRatio / 100;
        const originalLayerWidth = 322;

        if (currentDimension < 1) {
            currentDimension = 1;
        }

        const imageLayer = <img
            className="interactive-layer"
            src={overlay.image}
            style={{transform: `scale(${layerHeight * currentDimension})`, top: `calc(50% - 325px)`, left: `calc(50% - ${(layerHeight * currentDimension * originalLayerWidth) / 2}px)`}}
            alt=""
            onClick={() => overlay.onClick()}
        />;

        if (overlay.link) {
            return (
                <a href={overlay.link} target="_blank">
                    {imageLayer}
                </a>
            )
        }

        return imageLayer;
    }

    return (
        <div className={`entrance ${showAnimation ? 'zoom-in' : ''}`}>
            <Steps
                enabled={(showIntro && !introAlreadyDone)}
                steps={steps}
                initialStep={0}
                onExit={() => {
                    setShowIntro(false);
                }}
                onComplete={() => {
                    localStorage.setItem('home-intro-done', 'done');

                    setTimeout(() => {
                        setShowBgOverlay(true);
                    }, 200)

                    setTimeout(() => {
                        setShowPhone(true);
                    }, 400);
                }}
                options={{ hideNext: false, doneLabel: `Let's go inside!`, exitOnOverlayClick: false }}
            />
            <CSSTransition
                in={isLoading}
                nodeRef={loadingRef}
                timeout={300}
                classNames="entrance-loading"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div ref={loadingRef}>
                    <Loading />
                </div>
            </CSSTransition>
            <div className="entrance__layers">
                {isLocked && overlays.map((overlay) => renderOverlays(overlay))}

                <div className="vimeo-wrapper">
                    <Vimeo
                        video="https://vimeo.com/771733284/eeeada2908"
                        autoplay
                        muted
                        loop
                        background
                        onReady={() => {
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 1000);

                            setTimeout(() => {
                                setShowIntro(true);
                            }, 1800)
                        }}
                    />
                </div>
            </div>
            <CSSTransition
                in={showPhone}
                nodeRef={phoneRef}
                timeout={300}
                classNames="phone-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div className="phone-wrapper">
                    <Phone phoneRef={phoneRef} isLocked={isLocked} setShowPhone={setShowPhone} setShowBgOverlay={setShowBgOverlay} setIsLocked={setIsLocked} setShowAnimation={setShowAnimation} />
                </div>
            </CSSTransition>
            <CSSTransition
                in={showBgOverlay}
                nodeRef={bgOverlayRef}
                timeout={300}
                classNames="overlay-wrapper"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div className="overlay" ref={bgOverlayRef} onClick={() => {
                    setShowBgOverlay(false);
                    setShowPhone(false);
                }}></div>
            </CSSTransition>
            <CSSTransition
                in={showAnimation}
                nodeRef={unlockAnimationRef}
                timeout={3000}
                classNames="unlock-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div className="unlock-screen" ref={unlockAnimationRef}></div>
            </CSSTransition>
        </div>
    );
}

export default Home;
