import React, {CSSProperties} from 'react';
import Question from '../assets/question.jpeg'

const first = [
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F6637.png%3Fext%3Dpng',
    'https://i.imgur.com/0Aqp4e0.png',
    'https://i.imgur.com/zhcTVwj.png',
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5805.png%3Fext%3Dpng',
    'https://i.imgur.com/Ru7Q90Y.png',
    'https://i.imgur.com/kBpMqdC.gif',
    'https://i.imgur.com/X64x33q.png',
    Question,
    'https://i.imgur.com/Uy3JxRh.png',
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5888.png%3Fext%3Dpng',
    'https://i.imgur.com/YyJbMpM.jpg',
    'https://i.imgur.com/3N8PIZD.gif',
    'https://i.imgur.com/P57ySZf.gif',
    Question,
];

const second = [
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F6637.png%3Fext%3Dpng',
  'https://i.imgur.com/0Aqp4e0.png',
  'https://i.imgur.com/zhcTVwj.png',
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5805.png%3Fext%3Dpng',
  'https://i.imgur.com/Ru7Q90Y.png',
  'https://i.imgur.com/kBpMqdC.gif',
  'https://i.imgur.com/X64x33q.png',
  Question,
  'https://i.imgur.com/Uy3JxRh.png',
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5888.png%3Fext%3Dpng',
  'https://i.imgur.com/YyJbMpM.jpg',
  'https://i.imgur.com/3N8PIZD.gif',
  'https://i.imgur.com/P57ySZf.gif',
  Question,
];

const third = [
  'https://i.imgur.com/8T8Rho3.png',
  'https://i.imgur.com/0Aqp4e0.png',
  'https://i.imgur.com/zhcTVwj.png',
  'https://i.imgur.com/bSqyrdd.jpg',
  'https://i.imgur.com/Ru7Q90Y.png',
  'https://i.imgur.com/kBpMqdC.gif',
  'https://i.imgur.com/Xb65P5a.png',
  Question,
  'https://i.imgur.com/Uy3JxRh.png',
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5888.png%3Fext%3Dpng',
  'https://i.imgur.com/YyJbMpM.jpg',
  'https://i.imgur.com/3N8PIZD.gif',
  'https://i.imgur.com/P57ySZf.gif',
  Question,
];

const fourth = [
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F6637.png%3Fext%3Dpng',
  'https://i.imgur.com/0Aqp4e0.png',
  'https://i.imgur.com/zhcTVwj.png',
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5805.png%3Fext%3Dpng',
  'https://i.imgur.com/Ru7Q90Y.png',
  'https://i.imgur.com/kBpMqdC.gif',
  'https://i.imgur.com/X64x33q.png',
  Question,
  'https://i.imgur.com/Uy3JxRh.png',
  'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fcdn.valhallalabs.xyz%2Fape%2F5888.png%3Fext%3Dpng',
  'https://i.imgur.com/YyJbMpM.jpg',
  'https://i.imgur.com/3N8PIZD.gif',
  'https://i.imgur.com/P57ySZf.gif',
  Question,
];

const DURATION = 15000;
const ROWS = 4;
const TAGS_PER_ROW = 14;

const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr: string[]): string[] => [...arr].sort( () => .5 - Math.random() );

interface MyCustomCSS extends CSSProperties {
    '--duration': string;
    '--direction': string;
    '--color': string;
}

const InfiniteLoopSlider = ({children, duration, reverse = false}: {children: React.ReactElement, duration: number, reverse: boolean|number}) => {
  return (
    <div className='loop-slider' style={{
        '--duration': `${duration}ms`,
        '--direction': reverse ? 'reverse' : 'normal'
      } as MyCustomCSS}>
      <div className='inner'>
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({text}: {text: string}) => (
  <div className='tag'>
    <img src={text} alt="" />
  </div>
);

type Props = {
    giftsRef: React.LegacyRef<HTMLDivElement>|null;
    setShowGifts: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

function About({giftsRef, setShowGifts, setShowBgOverlay}: Props) {
  return (
    <div className="gifts" ref={giftsRef}>
        <div className='tag-list'>
            <InfiniteLoopSlider key={0} duration={random(DURATION - 5000, DURATION + 5000)} reverse={0 % 2}>
                <>
                    {shuffle(first).slice(0, TAGS_PER_ROW).map(tag => (
                        <Tag text={tag} />
                    ))}
                </>
            </InfiniteLoopSlider>
            <InfiniteLoopSlider key={1} duration={random(DURATION - 5000, DURATION + 5000)} reverse={1 % 2}>
                <>
                    {shuffle(second).slice(0, TAGS_PER_ROW).map(tag => (
                        <Tag text={tag} />
                    ))}
                </>
            </InfiniteLoopSlider>
            <InfiniteLoopSlider key={2} duration={random(DURATION - 5000, DURATION + 5000)} reverse={2 % 2}>
                <>
                    {shuffle(third).slice(0, TAGS_PER_ROW).map(tag => (
                        <Tag text={tag} />
                    ))}
                </>
            </InfiniteLoopSlider>
            <InfiniteLoopSlider key={3} duration={random(DURATION - 5000, DURATION + 5000)} reverse={3 % 2}>
                <>
                    {shuffle(fourth).slice(0, TAGS_PER_ROW).map(tag => (
                        <Tag text={tag} />
                    ))}
                </>
            </InfiniteLoopSlider>
        </div>
    </div>
  );
}

export default About;
