import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { get, ref } from "firebase/database";
import { auth, db } from '../services/firebase';

const PrivateRoutes = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);

    const getUserData = async (uid: string) => {
        const userRef = ref(db, `users/${uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            setUserData(snapshot.val());
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (user) {
            getUserData(user.uid);
        }
    }, [user]);

    return (
        <>
            {(!loading && !isLoading && user && userData) && <Outlet />}
            {((!loading && !user) || (!isLoading && (!userData))) && <Navigate to="/"/>}
        </>
    )
}

export default PrivateRoutes;