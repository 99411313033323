
import React, {useState, useEffect} from 'react';
import Overlays from './Overlays';
import Background from './Background';
import Menu from '../Menu';
import Intro from '../living-room/Intro';
import {useWindowSize} from '../../hooks/useWindow';

const Layers = React.memo(({overlays, bgRef, isPlaying, setIsLoading, setIsPlaying}: {overlays: any, bgRef: any, isPlaying: any, setIsLoading: any, setIsPlaying: any}) => {
    const introAlreadyDone = localStorage.getItem('living-intro-done');
    const [xPosition, setXPostion] = useState(0);
    const [leftDisabled, setLeftDisabled] = useState(false);
    const [rightDisabled, setRightDisabled] = useState(false);
    const [showIntro, setShowIntro] = useState(false);
    const [currentIntroStep, setCurrentIntroStep] = useState(1);
    const [width, height] = useWindowSize();

    useEffect(() => {
        if (bgRef.current && bgRef.current.player && bgRef.current.player.element) {
            const bgElement = bgRef.current.player.element;
            const centerPostion = -((bgElement.offsetWidth / 2) - (window.innerWidth / 2));
            setXPostion(centerPostion);
            setLeftDisabled(false);
            setRightDisabled(false);
        }

        if (showIntro) {
            setLeftDisabled(true);
        }
    }, [showIntro, width, height])

    const onLeft = () => {
        const newXPosition = xPosition + (window.innerWidth / 2);
        setRightDisabled(false);

        if (newXPosition > 0) {
            setLeftDisabled(true);
            setXPostion(0);
        } else {
            setXPostion(newXPosition);
        }

        if (showIntro) {
            setTimeout(() => {
                setLeftDisabled(true);
                setRightDisabled(true);
                setCurrentIntroStep(3);
                document.body.classList.add('step3');
            }, 1800)
        }
    }

    const onRight = () => {
        if (showIntro && currentIntroStep !== 1) {
            setRightDisabled(true);
            return;
        }

        const newXPosition = xPosition - (window.innerWidth / 2);
        const bgElement = bgRef.current.player.element;
        setLeftDisabled(false);

        if (newXPosition < -(bgElement.offsetWidth - window.innerWidth)) {
            setRightDisabled(true);
            setXPostion(-(bgElement.offsetWidth - window.innerWidth));
        } else {
            setXPostion(newXPosition);
        }

        if (showIntro) {
            setTimeout(() => {
                setCurrentIntroStep(2);
                setRightDisabled(true);
            }, 1800)
        }
    }

    return (
        <>
            <Menu isPlaying={isPlaying} setIsPlaying={setIsPlaying} showIntro={showIntro} currentStep={currentIntroStep} setCurrentIntroStep={setCurrentIntroStep} />
            <Intro showIntro={(showIntro && !introAlreadyDone)} currentIntroStep={currentIntroStep} />
            <div className={`navigate left ${leftDisabled ? 'disabled' : ''}`} onClick={onLeft}></div>
            <div className={`navigate right ${rightDisabled ? 'disabled' : ''}`} onClick={onRight}></div>
            <div style={{transform: `translateX(${xPosition}px)`, transition: 'transform 1.5s ease 0s'}}>
                <div className="living-room__body__layers">
                    <Overlays overlays={overlays} showIntro={showIntro} onDiscordClick={() => {
                        setShowIntro(false);
                        setRightDisabled(false);
                        setLeftDisabled(false);
                        document.body.classList.remove('step3');
                        document.body.classList.remove('step4');
                        document.body.classList.remove('step5');
                        localStorage.setItem('living-intro-done', 'done');
                    }} />
                    <Background bgRef={bgRef} setXPostion={setXPostion} setIsLoading={setIsLoading} setShowIntro={setShowIntro} introAlreadyDone={introAlreadyDone} setIsPlaying={setIsPlaying} width={width} height={height} />
                </div>
            </div>
        </>
    )
})

export default Layers;