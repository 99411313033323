import React from 'react';

type Props = {
    sponsersRef: React.LegacyRef<HTMLDivElement>|null;
    setShowSponsers: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

function Sponsers({sponsersRef, setShowSponsers, setShowBgOverlay}: Props) {
  return (
    <div className="sponsers" ref={sponsersRef}>
        <div className="wrapper">
            <div>
                <div className="item">
                    <a href="https://www.trippinapetribe.xyz" target="_blank">
                        <div className="polaroid">
                                <img src="https://moon.ly/uploads/nft/832b3f48-dc04-419d-abcc-be21b0c66ca1.jpg" />
                                <div className="caption">Trippin’ Ape Tribe</div>
                        </div>
                    </a>
                </div>

                <div className="item">
                    <a href="https://penfrens.io" target="_blank">
                        <div className="polaroid" style={{padding: '50px 0'}}>
                            <img src="https://i.imgur.com/7t54vS8.png" />
                            <div className="caption">Pen Frens</div>
                        </div>
                    </a>
                </div>

                <div className="item">
                    <a href="https://famousfoxes.com" target="_blank">
                        <div className="polaroid">
                            <img src="https://i.imgur.com/xYcVXbi.png" />
                            <div className="caption">Famous Fox Federation</div>
                        </div>
                    </a>
                </div>
            </div>
            <div>
                <div className="item">
                    <a href="https://gohumannft.com" target="_blank">
                        <div className="polaroid">
                            <img src="https://i.imgur.com/Ytis8Ln.png" />
                            <div className="caption">Go Human</div>
                        </div>
                    </a>
                </div>

                <div className="item">
                    <div className="polaroid">
                        <img src="https://i.scdn.co/image/ab6761610000e5eb208768fcd320ff4dca142d07" />
                        <div className="caption">TBA</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Sponsers;
