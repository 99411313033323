import React from 'react';

type Props = {
    gramophoneRef: React.LegacyRef<HTMLDivElement>|null;
    setShowGramophone: (showList: boolean) => void,
    setShowBgOverlay: (showBgOverlay: boolean) => void,
};

function Gramophone({gramophoneRef, setShowGramophone, setShowBgOverlay}: Props) {
  return (
    <div className="gramophone" ref={gramophoneRef}>
        <div className="gramophone-wrapper">
            <div className="record-player">
                <input type="checkbox" className="btn-play" id="btn-play" />
                <label htmlFor="btn-play" className="btn-play"></label>
                <div className="vinyl">
                    <div className="vinyl-circle"></div>
                    <div className="vinyl-glare"></div>
                    <div className="vinyl-center"></div>
                </div>
                <div className="disc-underTonarm"></div>
                <div className="stick">
                    <svg width="300" height="425">
                        <defs>
                            <filter id="shadow" x="-50%" y="0%" width="160%" height="150%">
                                <feDropShadow dx="-3" dy="5" stdDeviation="2" flood-color="#000000" flood-opacity="0.4" />
                            </filter>
                            <linearGradient id="Gradient1">
                                <stop className="stick-stop1" offset="0%"></stop>
                                <stop className="stick-stop2" offset="50%"></stop>
                                <stop className="stick-stop3" offset="100%"></stop>
                            </linearGradient>
                        </defs>
                        <rect ry="10" x="192" y="14" width="20" height="304" style={{fill: 'url(#Gradient1)', filter: 'url(#shadow)'}}></rect>
                        <rect x="172" width="60" height="24" style={{fill: 'url(#Gradient1)', filter: 'url(#shadow)'}}></rect>
                        <rect ry="10" x="303" y="190" width="20" height="120" style={{fill: 'url(#Gradient1)', transform: 'rotate(25deg)', filter: 'url(#shadow)'}}></rect>
                        <rect x="298" y="210" width="12" height="100" style={{fill: '#282424', transform: 'rotate(25deg)'}}></rect>
                        <rect x="320" y="210" width="8" height="100" style={{fill: '#282424', transform: 'rotate(25deg)'}}></rect>
                        <rect x="302" y="210" width="10" height="100" style={{fill: '#393739', transform: 'rotate(25deg)'}}></rect>
                        <rect x="313" y="210" width="10" height="100" style={{fill: '#393739', transform: 'rotate(25deg)'}}></rect>
                    </svg>
                </div>
                <div className="disc-overTonarm"></div>
                <div className="disc-overTonarm" id="hidden"></div>
            </div>
        </div>
        <div className="close-list">
        </div>
    </div>
  );
}

export default Gramophone;
