import React, { useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './App.scss';
import 'intro.js/introjs.css';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { createDefaultAuthorizationResultCache, createDefaultAddressSelector, SolanaMobileWalletAdapter } from '@solana-mobile/wallet-adapter-mobile';
import {
    CoinbaseWalletAdapter,
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    ExodusWalletAdapter,
    TokenPocketWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import PrivateRoutes from './components/PrivateRoutes';
import Home from "./components/pages/Home";
import LivingRoom from "./components/pages/LivingRoom";
import Logo from "./assets/logo.png";

function App() {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
      () => [
          new SolanaMobileWalletAdapter({
            appIdentity: { name: 'Solana Wallet Adapter App' },
            authorizationResultCache: createDefaultAuthorizationResultCache(),
            cluster: network,
            addressSelector: createDefaultAddressSelector(),
          }),
          new CoinbaseWalletAdapter(),
          new PhantomWalletAdapter(),
          new GlowWalletAdapter(),
          new ExodusWalletAdapter(),
          new SolletExtensionWalletAdapter(),
          new SlopeWalletAdapter(),
          new TokenPocketWalletAdapter(),
          new SolflareWalletAdapter({ network }),
      ],
      [network]
  );

  return (
    <div className="App">
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route element={<PrivateRoutes />}>
                    <Route element={<Home/>} path="/" />
                    <Route path="/living-room" element={<LivingRoom />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
              </Routes>
            </Router>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
      <div className="mobile-disclaimer">
          <img className="mobile-disclaimer__logo" src={Logo} alt="" />
          <p className="mobile-disclaimer__text">
            The Solmas page is designed to be a Desktop only expeirience. Grab yourself a hot chocolate, sit down at your computer and enjoy the Christmas vibez!
            <br /><br />
            Mint will be on mobile as well!
          </p>
      </div>
    </div>
  );
}

export default App;
