
import React from 'react';
import Tippy from '@tippyjs/react';

const Overlays = React.memo(({overlays, onDiscordClick, showIntro}: {overlays: any, onDiscordClick: any, showIntro: any}) => {
    const layerHeight = window.innerHeight / 1080;

    const renderOverlays = (overlay: any) => {
        const baseHeight = 1080;
        const windowHeight = window.innerHeight; // 100%
        const currentDimension = windowHeight / baseHeight;
        const leftPosition = overlay.left * currentDimension;
        const topOffset = (overlay.height - (overlay.height * currentDimension)) / 2;
        const topPosition = overlay.top * currentDimension - topOffset;

        const imageLayer = (
            <Tippy content={overlay.tooltip}>
                <img
                    className={`interactive-layer ${overlay.className}`}
                    src={overlay.image}
                    style={{transform: `scale(${layerHeight})`, top: `${topPosition}px`, left: `${leftPosition}px`}}
                    alt=""
                    onClick={() => {
                        if (showIntro && overlay.className === 'list-layer') {
                            onDiscordClick();

                            setTimeout(() => {
                                overlay.onClick();
                            }, 300)
                        } else {
                            overlay.onClick()
                        }
                    }}
                />
            </Tippy>
        );

        if (overlay.link) {
            return (
                <Tippy content={overlay.tooltip}>
                    <a
                        href={overlay.link}
                        target="_blank"
                        className={`interactive-layer ${overlay.className}`}
                        style={{transform: `scale(${layerHeight})`, top: `${topPosition}px`, left: `${leftPosition}px`}}
                    >
                        <img
                            src={overlay.image}
                            alt=""
                        />
                    </a>
                </Tippy>
            )
        }

        return imageLayer;
    }

    return (
        <>
            {overlays.map((overlay: any) => renderOverlays(overlay))}
        </>
    )
})

export default Overlays;