import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import toast, { Toaster } from 'react-hot-toast';
import ReactPlayer from 'react-player';
import Book from '../Book';
import List from '../List';
import Team from '../Team';
import Sponsers from '../Sponsers';
import Gramophone from '../Gramophone';
import About from '../About';
import Gifts from '../Gifts';
import Loading from '../Loading';
import Layers from '../living-room/Layers';
import 'tippy.js/dist/tippy.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { get, ref } from "firebase/database";
import { auth, db } from '../../services/firebase';
import Cross from '../../assets/close.svg';

// layers
import CalendarLayer from '../../assets/layers/calendar-glow.png';
import TwitterLayer from '../../assets/layers/twitter-glow.png';
import PicturesLayer from '../../assets/layers/pictures-glow.png';
import ListLayer from '../../assets/layers/list-glow.png';
import GramophoneLayer from '../../assets/layers/gramophone-glow.png';
import GiftsLayer from '../../assets/layers/gifts-glow.png';
import DiscordLayer from '../../assets/layers/discord-glow.png';
import BooksLayer from '../../assets/layers/bookshelf-glow.png';
import PolaroidLayer from '../../assets/layers/polaroid-glow.png';
import SnowGlobeLayer from '../../assets/layers/snow-globe-glow.png';
import BG from '../../assets/backgroudns/wood.jpg';

function LivingRoom() {
    const [isLoading, setIsLoading] = useState(true);
    const [showBook, setShowBook] = useState(false);
    const [showList, setShowList] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showSponsers, setShowSponsers] = useState(false);
    const [showGramophone, setShowGramophone] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [showGifts, setShowGifts] = useState(false);
    const [showBgOverlay, setShowBgOverlay] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [listSubmitted, setListSubmitted] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const bgRef: any = useRef(null);
    const bookRef = useRef(null);
    const listRef = useRef(null);
    const teamRef = useRef(null);
    const giftsRef = useRef(null);
    const gramophoneRef = useRef(null);
    const sponsersRef = useRef(null);
    const aboutRef = useRef(null);
    const bgOverlayRef = useRef(null);
    const loadingRef = useRef(null);

    const overlays = [
        {
            image: CalendarLayer,
            top: 239,
            left: 4923,
            height: 204,
            tooltip: 'Coming Soon',
            className: 'tba-layer',
            onClick: () => {

            },
        },
        {
            image: TwitterLayer,
            top: 214,
            left: 2284,
            height: 141,
            link: 'https://twitter.com/SolmasNFT',
            tooltip: 'Twitter',
            className: 'twitter-layer',
            onClick: () => {},
        },
        {
            image: PicturesLayer,
            top: 230,
            left: 187,
            height: 305,
            tooltip: 'Team',
            className: 'team-layer',
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowTeam(true);
                }, 200);
            },
        },
        {
            image: ListLayer,
            top: 266,
            left: 3244,
            height: 211,
            tooltip: 'Santas List closed',
            className: 'list-layer',
            onClick: () => {

            },
        },
        {
            image: GramophoneLayer,
            top: 425,
            left: 4095,
            height: 274,
            tooltip: 'Coming Soon',
            className: 'music-layer',
            onClick: () => {
                //setShowBgOverlay(true);

                /*setTimeout(() => {
                    setShowGramophone(true);
                }, 200);*/
            },
        },
        {
            image: GiftsLayer,
            top: 847,
            left: 5100,
            height: 211,
            tooltip: 'Gifts',
            className: 'gifts-layer',
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowGifts(true);
                }, 200);
            },
        },
        {
            image: DiscordLayer,
            top: 276,
            left: 2425,
            height: 141,
            tooltip: 'Discord',
            className: 'discord-layer',
            link: 'https://discord.gg/kDff5W8b',
            onClick: () => {},
        },
        {
            image: BooksLayer,
            top: 252,
            left: 926,
            height: 195,
            tooltip: 'Story',
            className: 'story-layer',
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowBook(true);
                }, 200);
            },
        },
        {
            image: PolaroidLayer,
            top: 285,
            left: 4486,
            height: 144,
            tooltip: 'Collabs',
            className: 'collabs-layer',
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowSponsers(true);
                }, 200);
            },
        },
        {
            image: SnowGlobeLayer,
            top: 605,
            left: 1543,
            height: 98,
            tooltip: 'Whitepaper',
            className: 'whitepaper-layer',
            onClick: () => {
                setShowBgOverlay(true);

                setTimeout(() => {
                    setShowAbout(true);
                }, 200);
            },
        },
    ];

    const getUserData = async (uid: string) => {
        const userRef = ref(db, `users/${uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            const userData = snapshot.val();

            if (userData && userData.santasList) {
                setListSubmitted(true);
            }
        }
    }
    useEffect(() => {
        document.body.classList.add('living');
        const connection = (window as any).navigator.connection || (window as any).navigator.mozConnection || (window as any).navigator.webkitConnection;

        if (connection && connection.downlink < 2) {
            toast.loading('Slow Internet detected, things might look broken!');
        }

        if (user) {
            getUserData(user.uid);
        }
        return () => {
            //audio.pause();
        };
    }, []);

    return (
        <div className="living-room">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <ReactPlayer className="soundcloud" playing={isPlaying} loop={true} url="https://soundcloud.com/djmateria/lofi?si=76c0b4c7213a46c989f9061209a44872&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" />
            <CSSTransition
                in={isLoading}
                nodeRef={loadingRef}
                timeout={300}
                classNames="entrance-loading"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div ref={loadingRef}>
                    <Loading />
                </div>
            </CSSTransition>
            <div className="living-room__body">
                <Layers overlays={overlays} bgRef={bgRef} isPlaying={isPlaying} setIsLoading={setIsLoading} setIsPlaying={setIsPlaying} />
            </div>
            <CSSTransition
                in={showBook}
                nodeRef={bookRef}
                timeout={300}
                classNames="book-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <Book bookRef={bookRef} setShowBook={setShowBook} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showList}
                nodeRef={listRef}
                timeout={300}
                classNames="list-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <List listRef={listRef} setShowList={setShowList} setShowBgOverlay={setShowBgOverlay} setListSubmitted={setListSubmitted} />
            </CSSTransition>
            <CSSTransition
                in={showTeam}
                nodeRef={teamRef}
                timeout={300}
                classNames="team-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <Team teamRef={teamRef} setShowTeam={setShowTeam} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showSponsers}
                nodeRef={sponsersRef}
                timeout={300}
                classNames="sponsers-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <Sponsers sponsersRef={sponsersRef} setShowSponsers={setShowSponsers} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showGramophone}
                nodeRef={gramophoneRef}
                timeout={300}
                classNames="gramophone-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <Gramophone gramophoneRef={gramophoneRef} setShowGramophone={setShowGramophone} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showAbout}
                nodeRef={aboutRef}
                timeout={300}
                classNames="about-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <About aboutRef={aboutRef} setShowAbout={setShowAbout} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showGifts}
                nodeRef={giftsRef}
                timeout={300}
                classNames="about-animation"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <Gifts giftsRef={giftsRef} setShowGifts={setShowGifts} setShowBgOverlay={setShowBgOverlay} />
            </CSSTransition>
            <CSSTransition
                in={showBgOverlay}
                nodeRef={bgOverlayRef}
                timeout={300}
                classNames="overlay-wrapper"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
            >
                <div ref={bgOverlayRef}>
                    <div className="overlay" style={{background: `url(${BG})`}}></div>
                    <div className="close-modal">
                        <img src={Cross} alt="" onClick={() => {
                            if (showAbout) {
                                setShowAbout(false);
                            } else if (showBook) {
                                setShowBook(false);
                            } else if (showGifts) {
                                setShowGifts(false);
                            } else if (showGramophone) {
                                setShowGramophone(false)
                            } else if (showList) {
                                setShowList(false);
                            } else if (showSponsers) {
                                setShowSponsers(false);
                            } else if (showTeam) {
                                setShowTeam(false);
                            }

                            setTimeout(() => {
                                setShowBgOverlay(false);
                            }, 200);
                        }} />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default LivingRoom;
