
import React, {useState, useEffect} from 'react';
import { FaPlus } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { IoMusicalNotesOutline } from "react-icons/io5";
import Key from '../assets/passkey.svg';
import { useAuthState } from 'react-firebase-hooks/auth';
import { get, ref } from "firebase/database";
import { auth, db } from '../services/firebase';

const Layers = React.memo(({setIsPlaying, isPlaying, showIntro, currentStep, setCurrentIntroStep} : {showIntro: any, setIsPlaying: any, isPlaying: any, currentStep: any, setCurrentIntroStep: any}) => {
    const [isActive, setIsActive] = useState(false);
    const [keys, setKeys] = useState([]);
    const [user, loading, error] = useAuthState(auth);

    const getUserData = async (uid: string) => {
        const userRef = ref(db, `users/${uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            const userData = snapshot.val();

            if (userData && userData.keys) {
                setKeys(userData.keys)
            }
        }
    }

    useEffect(() => {
        if (user) {
            getUserData(user.uid);
        }
    }, [user]);

    return (
        <div id="circularMenu" className={`circular-menu ${isActive ? 'active' : ''}`}>
            <a className="floating-btn" onClick={() => {
                if (showIntro && currentStep === 3) {
                    setIsActive(!isActive);
                    setCurrentIntroStep(4);
                    document.body.classList.add('step4');
                } else {
                    setIsActive(!isActive);
                }
            }}>
                <FaPlus className="fa fa-plus" size={24} />
            </a>

            <menu className="items-wrapper">
                <a className="menu-item"></a>
                <a className="menu-item sign-out" title="Sign out" onClick={() => {
                    auth.signOut();
                }}>
                    <FiLogOut size={24} />
                </a>
                <a className={`menu-item music ${!isPlaying ? 'off' : ''}`} title="Play / Pause" onClick={() => setIsPlaying(!isPlaying)}>
                    <IoMusicalNotesOutline size={28} />
                </a>
                <a className="menu-item keys" onClick={() => {
                    if (showIntro && currentStep === 4) {
                        setCurrentIntroStep(5);
                        document.body.classList.add('step5');
                    }
                }} title="Keys" target="_blank" href={`https://twitter.com/intent/tweet?text=I%20nominate%20for%20Santa%27s%20list%F0%9F%93%9C:%0A%0A1.%0A2.%0A3.%0A%0AApply%20now%20for%20Santa%27s%20list%20at%20solmas.io%20%F0%9F%8E%85%0A@SolmasNFT&hashtags=SolmasKeys`} >
                    <img src={Key} alt="" />
                </a>
            </menu>

        </div>
    )
})

export default Layers;
